import { useMatches } from "@remix-run/react";

export const DISABLE_COOKIE_CONSENT_MANAGER_KEY = "disableCookieConsentManager";

export const useDisableCookieConsentManager = () => {
  const matches = useMatches();

  // filter to the match which matches the handle
  const match = matches.find((m) => (m.handle as Record<string, any>)?.[DISABLE_COOKIE_CONSENT_MANAGER_KEY]);

  return !!match;
};
